<template>
  <div>
    <div class="box-shadow bg-white">
      <div class="d-flex-center notice-bar p-20 mb-30">
        <div v-for="(item, index) in noticeList" :key="index">
          <el-link
            type="primary"
            :title="item.title"
            class="link"
            @click="$nav.push('/notice/details/' + item.id)"
            >{{ item.title }}</el-link
          >
          <span class="mx-20">/</span>
        </div>
        <div>
          <el-link type="primary" class="link" @click="$nav.push('/notice')">{{
            $t("n_more")
          }}</el-link>
        </div>
      </div>
    </div>

    <!-- 申購彈窗 -->
    <el-dialog
      :title="$t('issue.onOrderShow')"
      width="500px"
      top="100px"
      :visible.sync="orderShow"
      :close-on-click-modal="false"
    >
      <el-form
        ref="orderForm"
        v-loading="orderLoading"
        element-loading-background="#ffffff88"
        class="white-form"
        :model="orderFormData"
        label-position="top"
        :rules="rules"
      >
        <!-- <el-form-item :label="'可申購額度'">


          <span class="">{{coin_detail.allow_money?coin_detail.allow_money+'USDT':''}}</span>
        </el-form-item> -->
        <div class="can_num">
          <div class="can_num_h">{{$t('xinbiyure.can_num_h')}}：</div>
          <div class="can_num_p">
            <span class="can_num_p_num">{{
              coin_detail.allow_money ? coin_detail.allow_money : 0
            }}</span>
            <span> USDT</span>
          </div>
        </div>

        <!-- 新增可用餘額 -->
        <div class="can_num">
          <div class="can_num_h">{{$t('xinbiyure.can_num_h_u')}}：</div>
          <div class="can_num_p">
            <span class="can_num_p_num">{{
              balance_coin ? balance_coin : 0
            }}</span>
            <span> USDT</span>
          </div>
        </div>
        <!-- 新增可用餘額 -end -->

        <!-- :label="$t('issue.label')" -->
        <el-form-item prop="amount">
          <el-input
            v-model="orderFormData.amount"
            size="large"
            type="number"
            prefix-icon="el-icon-money"
            autocomplete="off"
            :placeholder="$t('xinbiyure.orderFormData_amount1')+' USDT'"
          >
          </el-input>
        </el-form-item>
        <div class="text-center">
          <el-button type="info" icon="el-icon-close" @click="onOrderHide()">{{
            $t("issue.Cancel")
          }}</el-button>
          <el-button
            type="primary"
            icon="el-icon-s-promotion"
            @click="submitOrderForm()"
            >{{ $t("issue.Submit") }}</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!-- 申購彈窗 -end -->

    <el-card class="content-box">
      <div class="top-info py-20">
        <div class="flex-textcontent">
          <div
            v-if="coin_detail.logo"
            class="icon icon-style-yuan icon-stars newing flex-textcontent-img"
            :style="{ backgroundImage: 'url(' + coin_detail.logo + ')' }"
          />
          <div class="outline_is">
            <div class="outline_is_top">
              <h1 class="mr-auto pair-coin my-0">
                {{ $route.params.pair.toUpperCase() }} /
                {{ $route.params.coin.toUpperCase() }}
              </h1>
              <el-button
                type="success"
                icon="el-icon-shopping-cart-2"
                class="w-100"
                native-type="button"
                @click="onOrderShow"
                >{{ $t("issue.onOrderShow") }}</el-button
              >
            </div>
            <div class="outline_is_bottom">
              <ul>
                <li>
                  <h5>{{ $t("xinbiyure.issue_price") }}</h5>
                  <span>{{ coin_detail.issue_price }}</span>
                </li>
                <li>
                  <h5>{{ $t("xinbiyure.issue_date") }}</h5>
                  <span>{{ coin_detail.issue_date }}</span>
                </li>
                <li>
                  <h5>{{ $t("xinbiyure.issue_amount") }}</h5>
                  <span>{{ coin_detail.issue_amount }}</span>
                </li>
                <li>
                  <h5>{{ $t("xinbiyure.guimo") }}</h5>
                  <span>{{ coin_detail.guimo }}</span>
                </li>
                <!-- 累計申購額度 -->
                <!-- <li>
                  <h5>{{ $t("xinbiyure.use_issue_amount") }}</h5>
                  <span>{{ coin_detail.use_issue_amount }}</span>
                </li> -->

                <li>
                  <h5>{{ $t("xinbiyure.close_day") }}</h5>
                  <span>{{ coin_detail.close_day }}</span>
                </li>
                <li>
                  <h5>{{ $t("xinbiyure.published_date") }}</h5>
                  <span>{{ coin_detail.published_date }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <div class="content-box mb-50 mt-20">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="bg-white box-shadow border-r">
            <div class="issue-content">
              <div class="issue-content-title">
                <h4>{{ $t("issue.detail") }}</h4>
              </div>
              <div class="issue-textarea" v-loading="marketLoading">
                <div class="issue-render" v-html="coin_detail.introduce"></div>
              </div>
            </div>
          </div>
        </el-col>

        <!-- <el-col :span="6">
          <div class="bg-white box-shadow border-r">
              立即申購
          </div>
        </el-col> -->
      </el-row>
    </div>
  </div>
</template>

<script>
import coinLogo from "@/assets/coin/logo";

export default {
  data() {
    return {
      coinLogo,
      buyLoading: false,
      noticeList: [],
      orderFormData: {
        pair: this.$route.params.pair,
        coin: this.$route.params.coin,
        issue_symbol_id: this.$route.params.id,
      },
      marketLoading: false,
      coin_detail: {},
      orderShow: false,
      orderLoading: false,

      rules: {
        amount: [
          {
            required: true,
            message: this.$t("issue.placeholder"),
            trigger: "blur",
          },
        ],
      },

      //新增可用餘額 usdt
      balance_coin:'',
    };
  },
  computed: {},
  watch: {
    $route: function () {},
  },
  created: function () {
    this.fatchNotice();
    this.tabChange();

    // 新增可用餘額usdt 現貨
    this.getAccountBalance();
  },
  beforeDestroy: function () {},
  mounted: function () {},
  destroyed: function () {},
  methods: {
    // 新增獲取餘額usdt v1/symbol/getAccountBalance
    getAccountBalance: async function () {
      this.loading = true;
      try {
        const { data } = await this.$request.post("v1/symbol/getAccountBalance",{coin:'usdt',pair:'usdt'});
        this.balance_coin = data.balance.balance_coin
      } catch (e) {

      }
    },


    fatchNotice: async function () {
      const { data } = await this.$request.post("v1/notice");
      this.noticeList = data.data;
    },

    tabChange: function () {
      this.marketLoading = true;
      this.$requestNotState
        .post("v1/issue_symbol/getInfo", {
          id: this.$route.params.id,
        })
        .then(({ data }) => {
          this.marketLoading = false;
          this.coin_detail = data;

          // 計算募資規模打回item 募资规模=发行数量*发行价（取整，不需要小数）
          // Math.round四捨五入
          this.coin_detail.guimo = Math.round(
            this.floatingpoint.mul(data.issue_amount, data.issue_price)
          );

          // console.log(this.coin_detail);
        })
        .catch(() => {
          this.marketLoading = false;
        });
    },

    //微合约下单后返回结果接口
    fatchOrderItem: function () {
      if (!this.orderFormData.id) return;
      this.$request
        // .post("contract/item", this.orderFormData)
        .post(
          "v1/time_set/getInfo",
          this.orderFormData
        )
        .then(({ data }) => {
          if (data.state == 0) {
            window.setTimeout(() => {
              this.fatchOrderItem;
            }, 500);
          } else {
            this.orderFormData.state = data.state;
          }
        });
    },

    //申購
    submitOrderForm: async function () {
      let that = this;

      try {
        await this.$refs.orderForm.validate();

        if (
          Number(this.orderFormData.amount) >
          Number(this.coin_detail.allow_money)
        ) {
          return that.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: that.$t("xinbiyure.showToast1"),
          });
        }

        // 新增可用餘額判斷
        if (
          Number(this.orderFormData.amount) >
          Number(this.balance_coin)
        ) {
          return that.$message({
            showClose: true,
            type: "error",
            duration: 2000,
            message: that.$t("xinbiyure.showToast_u"),
          });
        }

        this.orderLoading = true;
        // this.orderFormData.amount = Number(this.orderFormData.amount)*100

        let $dataJson = {
          amount: this.orderFormData.amount,
          issue_symbol_id: this.$route.params.id,
          //新版本帶上is_new
          is_new:1,
        }

        let $data = await this.$request.post(
          "v1/issue_symbol/subscription",
          $dataJson
        );

        that.$message({
          showClose: true,
          type: "success",
          duration: 2000,
          message: $data.message,
          onClose: function () {
            that.orderFormData.amount = "";
            that.orderShow = false;
            that.orderLoading = false;

            that.tabChange();
            // 新增可用餘額usdt 現貨
            that.getAccountBalance();

            //用戶中心新幣申購列表
            that.$router.push("/user/account/issue_order");
          },
        });
      } catch (error) {
        this.orderLoading = false;
      }
    },

    onOrderShow: function () {
      if (!this.$store.state.userToken) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userToken"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            // this.$router.push("../user/login");
            this.$router.push("/user/login");
          })
          .catch((action) => {});
      }
      if (this.$store.state.userVerified != 3) {
        return this.$confirm(
          this.$t("fiat_currency.message.p_userVerified"),
          this.$t("fiat_currency.message.tips"),
          {
            distinguishCancelAndClose: true,
            confirmButtonText: this.$t("fiat_currency.message.confirm"),
            cancelButtonText: this.$t("fiat_currency.message.cancel"),
          }
        )
          .then(() => {
            // this.$router.push("../user/settings/verify");
            this.$router.push("/user/settings/verify");
          })
          .catch((action) => {});
      }
      this.orderShow = true;
    },
    onOrderHide: function () {
      this.orderShow = false;
      this.orderFormData.amount = "";
    },
  },
};
</script>
<style>
.white-form input {
  color: black !important;
}
</style>
<style lang="scss" scoped>
.notice-bar {
  .link {
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.top-info {
  .pair-coin {
    font-size: 32px;
  }
}
.kline-box {
  position: relative;
  .kline-tools {
    position: absolute;
    top: 10px;
    right: 20px;
  }
  .kline-view {
    height: 500px;
    border: none;
    width: 1160px;
  }
}
.tabs {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid $bgColor;
  .tab {
    padding-bottom: 18px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
  }
  .active {
    color: $--color-primary;
    border-bottom: 2px solid $--color-primary;
  }
}
.mbp-buy {
  background-color: rgba(255, 59, 59, 0.07);
}
.mbp-sell {
  background-color: rgba(41, 194, 120, 0.07);
}

// 新增样式
.top-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-textcontent {
  margin-right: 0;
}
.right-textcontent {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.right-textcontent > div {
  min-width: 128px;
  padding-left: 20px;
  box-sizing: border-box;
}
.right-textcontent > div:nth-child(2) {
  min-width: 118px;
}
.right-textcontent > div:nth-last-child(1) {
  min-width: 148px;
}
.theme-title {
  font-size: 15px;
  margin: 20px 0 0;
  padding: 0 20px;
  box-sizing: border-box;
}
.theme-title span {
  padding-left: 10px;
  border-left: 3px solid #f9a825;
  color: #333;
}
.top-info .left-textcontent h1 {
  font-size: 34px;
}
.radio-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.radio-group label {
  margin: 0 5px 10px 5px !important;
}
.newstyle {
  padding: 20px;
}
.newstyle-col {
  padding-top: 20px !important;
}

// 顶部信息样式
.top-text {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebedf0;
  box-sizing: border-box;
}
.top-text .top-text-item {
  flex: 1;
  text-align: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.top-text-h {
  font-size: 14px;
  color: #999999;
}
.top-text-p {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}
.in-select {
  width: 120px;
  font-size: 12px;
}
.pagination-box {
  text-align: center;
  padding-top: 10px;
  box-sizing: border-box;
}

// 订单类型
.etab-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #e3e6e9;
  box-sizing: border-box;
  margin-bottom: 5px;
}
.title-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.title-label-item {
  font-size: 14px;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  line-height: 14px;
}
.title-label-cli {
  width: 1px;
  height: 16px;
  background: #999;
}
.title-label .active {
  color: #f9a825;
}

// 持仓列表
.issue-content {
  padding: 20px;
  box-sizing: border-box;
}
.issue-content-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.issue-content h3 {
  font-size: 34px;
  margin: 0;
  line-height: 50px;
}
.button-outline {
  margin-left: 20px;
}
.issue-textarea {
  padding: 40px 0;
  box-sizing: border-box;
  border-top: 1px solid #e3e6e9;
}

.left-textcontent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.newing {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.mr-auto.pair-coin {
  margin-right: 10px;
}
.issue-content-title {
  padding-top: 10px;
}
.issue-content-title h4 {
  font-size: 18px;
  margin: 0;
}
.issue-render {
  width: 100%;
  // text-align: center;
}
.issue-render img {
  max-width: 100% !important;
}

// 新樣式
.flex-textcontent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.flex-textcontent .flex-textcontent-img {
  width: 300px;
  height: 300px;
  margin: 0;
}
ul {
  list-style: none;
  margin: 0px;
  padding: 0;
}
.outline_is {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 700px;
  margin-left: 30px;
}
.outline_is_top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
}
.outline_is_top h1 {
  white-space: nowrap;
}
.outline_is_bottom {
  padding: 20px;
  box-sizing: border-box;
  background: #f1f1f1;
}
.outline_is_bottom ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.outline_is_bottom ul li {
  width: 25%;
  text-align: center;
  padding: 20px 0;
}
.outline_is_bottom ul li h5 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #999;
  margin: 0 0 10px;
}
.outline_is_bottom ul li span {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.can_num {
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-bottom: 5px;
}
.can_num_p_num {
  font-size: 18px;
  color: red;
  font-weight: bold;
}
</style>